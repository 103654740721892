module.exports = [{
      plugin: require('/Users/erik/Documents/code/gatsby/aogatsby1/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1280},
    },{
      plugin: require('/Users/erik/Documents/code/gatsby/aogatsby1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GatsbyJS","short_name":"GatsbyJS","start_url":"/","background_color":"#6b37bf","theme_color":"#6b37bf","display":"standalone","icon":"static/images/aog-logo-square-256px.png","crossOrigin":"use-credentials"},
    },{
      plugin: require('/Users/erik/Documents/code/gatsby/aogatsby1/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
